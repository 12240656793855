import mapboxgl, { Map } from 'mapbox-gl'

export const makeMap = (id: string | HTMLElement, geoBounds: any, mapStyleId: string, mapboxSettings: any | null): Promise<Map> => {
  if (process.env.MAPBOX_TOKEN === undefined) {
    throw new Error('MAPBOX_TOKEN is not defined')
  }
  mapboxgl.accessToken = process.env.MAPBOX_TOKEN

  const mapDefaults = {
    container: id,
    center: geoBounds.getCenter(),
    zoom: 12,
    // 'minZoom' must be same as 'zoom' to prevent zooming out, because highlights 
    // is not recalculated on zoom out. Please change geo bounds instead.
    minZoom: 12,
    maxZoom: 14,
    // bounds: geoBounds,  // Bearing and Pitch is not supported with bounds
    style: `mapbox://styles/${mapStyleId}`,
    pitch: 60, // pitch in degrees
    bearing: 10, // bearing in degrees
    attributionControl: false,
    scrollZoom: false,
    doubleClickZoom: true,
    antialias: true,
    config: {
      basemap: {
          // lightPreset: 'night' // 'day' | 'night' | 'dawn' | 'dusk'
      }
    }
  }

  let mapSettings = mapDefaults
  if (mapboxSettings !== null) {
    try {
      mapSettings = Object.assign(mapDefaults, mapboxSettings) 
    } catch (error) {
      console.error('Failed to apply Mapbox JSON from tenant settings', error)
    }
  }
  const map = new Map(mapSettings)

  map.addControl(new mapboxgl.FullscreenControl())

  return new Promise(resolve => {
    map.on('load', () => resolve(map))
  })
}
