import { Map, Marker } from 'mapbox-gl';

const START_JOURNEY_AFTER_SECS = 0;
const SPEED = 0.2;
const HIGHLIGHT_ZOOM = 16;
const HIGHLIGHT_BEARING = 60;
const HIGHLIGHT_DURATION = 5;

let highlightsStore: any[] = [];
let highlightIndex = -1;
let nextHighlight: any = undefined;

export const isJourneyEnabled = process.env.JOURNEY_ENABLED?.toString() === 'true';

export const startJourney = (map: Map, highlights: any[]) => {
    if (!isJourneyEnabled) {
        console.warn('Journey feature is disabled');
        return;
    }

    highlightsStore = highlights;
    setTimeout(() => {
        console.log('Starting journey');
        nextHighlight = chooseNextHighlight();
        preflightToNextHighlight(map);
        goToNextHighlight(map);
    }, START_JOURNEY_AFTER_SECS * 1000);
    console.log('Starting journey in', START_JOURNEY_AFTER_SECS, 'seconds');
}

const goToNextHighlight = (map: Map) => {
    if (highlightsStore.length > 0) {
        flyToHighlight(map, nextHighlight);
        map.once('moveend', () => {
            nextHighlight = chooseNextHighlight();
            preflightToNextHighlight(map);
            // Duration the highlight is on screen after interaction.
            window.setTimeout(() => {
                goToNextHighlight(map);
            }, HIGHLIGHT_DURATION * 1000); // After callback, show the location for X seconds.
        });
    } else {
        console.warn('No highlights to fly to');
    }
}

export const flyToHighlight = (map: Map, highlight: any) => {
    if (!isJourneyEnabled) {
        console.warn('Journey feature is disabled');
        return;
    }
    console.log('Flying to ', highlight.title);
    console.debug(highlight);
    map.flyTo({
        center: [
            highlight.geoPoint.lon,
            highlight.geoPoint.lat
        ],
        essential: true,
        zoom: HIGHLIGHT_ZOOM,
        bearing: HIGHLIGHT_BEARING,
        speed: SPEED, // Make the flying slow.
        curve: 1, // Change the speed at which it zooms out.
    });
}

const chooseNextHighlight = () => {
    highlightIndex++;
    if (highlightIndex >= highlightsStore.length) {
        highlightIndex = 0;
    }
    return highlightsStore[highlightIndex];
}

const preflightToNextHighlight = (map: Map) => {
    console.log('Preflight to next highlight', nextHighlight);

    // Create a "paper marker" for the next highlight.
    const marker = createArticleBoxMarker(nextHighlight);
    marker.addTo(map);
    highlightsStore[highlightIndex] = marker;

    // Prepare Mapbox animation to next so it'll be ready 
    // when we need it. All rasters will be preloaded.
    map.flyTo({
        center: [
            nextHighlight.geoPoint.lon,
            nextHighlight.geoPoint.lat
        ],
        //@ts-ignore
        preloadOnly: true,
        essential: true,
        zoom: HIGHLIGHT_ZOOM,
        bearing: HIGHLIGHT_BEARING,
        curve: 1
    });
}

const createArticleBoxMarker = (highlight: any) => {
    const el = document.createElement('div');
    el.className = 'highlight-marker';
    el.innerHTML =
        `<h2>${highlight.title}</h2>` +
        `<p>${highlight.description}</p>`;

    // TODO: not needed anymore?
    const shiftedLatitude = highlight.geoPoint.lat + Math.random() * 0.002;
    const shiftedLongitude = highlight.geoPoint.lon + Math.random() * 0.002;

    const marker = new Marker(el)
        .setLngLat([shiftedLongitude, shiftedLatitude])
        .setOffset([0, -50]);

    return marker;
}

const rotateCamera = (map: Map, timestamp: number) => {
    // clamp the rotation between 0 -360 degrees
    // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
    map.rotateTo((timestamp / 100) % 360, { duration: 0 });
    console.log('Rotating camera', timestamp)
    // Request the next frame of the animation.
    requestAnimationFrame(() => rotateCamera);
}
