/* Copied from localix-reactnative-app */

export const colorInfo = {
    primaryColor: '#0a324b',
    primaryColorDark: '#b7eaae',
  
    blueColor: 'rgba(10,85,130,1.0)',   // #0a5582
    purpleColor: 'rgba(83,46,127,1.0)', // #532e7f
    pinkColor: 'rgba(221,59,113,1.0)',  // #dd3b71
    yellowColor: 'rgba(255,205,0,1.0)', // #ffcd00
    greenColor: 'rgba(0,107,82,1.0)',   // #006b52
  
    darkModeBlueColor: 'rgba(160,212,233,0.8)',
    darkModePurpleColor: 'rgba(206,180,214,0.8)',
    darkModePinkColor: 'rgba(246,144,144,0.8)',
    darkModeYellowColor: 'rgba(248,229,154,0.8)',
    darkModeGreenColor: 'rgba(177,225,191,0.8)'
  };
  
export const getColorByKind = (kind: any, theme = 'light') => {
    switch (kind) {
      case 'News':
      case 100: // news
        return theme == 'light' ? colorInfo.blueColor : colorInfo.darkModeBlueColor;
      case 'Emergency':
      case 200: // emergency
        return theme == 'light' ? colorInfo.pinkColor : colorInfo.darkModePinkColor;
      case 'Info':
      case 300: // info
        return theme == 'light' ? colorInfo.greenColor : colorInfo.darkModeGreenColor;
      case 'Social':
      case 400: // social
        return theme == 'light' ? colorInfo.yellowColor : colorInfo.darkModeYellowColor;
      case 'Sponsored':
      case 500: // sponsored
        return theme == 'light' ? colorInfo.yellowColor : colorInfo.darkModeYellowColor;
    }
    return 'darkgrey';
  }
  
  export const getLightColorByKind = (kind: any, theme = 'light') => {
    if(theme == 'light') {
      // make more opacity than on the highlight cards
      return getColorByKind(kind).replace('1.0)', '0.8)');
    } else {
      // make them more sharpy than on the highlight cards
      return getColorByKind(kind).replace('0.8)', '1.0)');
    }
  }