export const renderFullscreenButton = () => {
    const btn = document.createElement('fullscreen-instruction')!;
    btn.innerText = 'Start';
    btn.title = 'Toggle fullscreen';
    btn.setAttribute('aria-label', 'Toggle fullscreen');
    btn.onclick = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            makeFullscreenRequest();
        }
    };
}

const makeFullscreenRequest = () => {
    let elem = document.documentElement;
    elem
        .requestFullscreen({ navigationUI: 'show' })
        .then(() => { })
        .catch((err) => {
            alert(
                `An error occurred while trying to switch ` +
                `into fullscreen mode: ${err.message} (${err.name})`,
            );
        });
}