import { Map, Marker, Popup } from "mapbox-gl";
import { getColorByKind } from "./color";
import { getPermalink } from "./services/highlight-service";

export const loadHighlightsAsMarkers = (map: Map, highlights: any[]) => {
    highlights.forEach((highlight) => {
        if (!highlight.isMapMarkerFriendly) {
            return;
        }

        const color = getColorByKind(highlight.kind, 'dark');

        const el = document.createElement('div');
        el.className = 'circle-marker';

        // Add emoji if available into the DOM element
        if (highlight.emoji) {
            const emoji = document.createElement('div');
            emoji.className = 'emoji';
            emoji.innerHTML = highlight.emoji;
            el.className += ' emoji-marker';
            el.appendChild(emoji);
        }
        // Add icon if available into the DOM element
        else if (highlight.iconList && highlight.iconList.length > 0) {
            const icon = document.createElement('img');
            icon.src = `${process.env.ICON_BASE_URL}/${highlight.iconList[0]}`;
            el.appendChild(icon);
        }
        else {
            el.style.backgroundColor = color;
        }

        // Render the marker on the map
        let popupHtml = `<h3>${highlight.title}</h3><p>${highlight.intro}</p>`;
        popupHtml += `<a href="${getPermalink(highlight)}" target="_blank">Öppna</a>`;
        const marker = new Marker(el)
            .setLngLat([highlight.geoPoint.lon, highlight.geoPoint.lat])
            .setPopup(new Popup().setHTML(popupHtml));
        marker.addTo(map);
    });
}