import * as signalR from '@microsoft/signalr';
import { Map, LngLat } from 'mapbox-gl';
import { flyToHighlight } from './journey';
import { makeHighlightRow } from './linklist';
import { speakAboutUpcomingHighlight } from './speaker';

export const isSignalrEnabled = process.env.SIGNALR_ENABLED?.toString() === 'true';

export const initSignalr = (map: Map) => {
    if (!isSignalrEnabled) {
        console.warn('SignalR feature is disabled');
        return;
    }

    if (process.env.SIGNALR_URL === undefined) {
        console.warn('SignalR URL is not defined');
        return;
    }

    const conn = new signalR.HubConnectionBuilder()
        .withUrl(process.env.SIGNALR_URL, {
            withCredentials: false
        })
        .configureLogging(signalR.LogLevel.Debug)
        .withAutomaticReconnect()
        .build();

    async function start() {
        try {
            await conn.start();
            console.log(`SignalR connected with connection-ID ${conn.connectionId}.`);
        } catch (err) {
            console.log(err);
            console.log('Retrying in 5 seconds...');
            setTimeout(start, 5000);
        }
    };

    const minRankThreshold = parseInt(process.env.MIN_RANK_THRESHOLD || '0');
    conn.on('addHighlight', (highlightJsonString: string) => {
        const highlight = JSON.parse(highlightJsonString);
        console.log('New highlight from SignalR', highlight);

        // Is coordinates within map bounds?
        const highlightCoordinates = new LngLat(
            highlight.geoPoint.lon, highlight.geoPoint.lat);
        const bounds = map.getBounds();
        if (!bounds || !bounds.contains(highlightCoordinates)) {
            console.log('Highlight is outside map bounds, ignoring');
            return;
        }

        if (highlight.rank >= minRankThreshold) {
            speakAboutUpcomingHighlight(highlight, null);
            flyToHighlight(map, highlight);
        }

        insertInHighlightList(highlight);
    });

    start();
}

const insertInHighlightList = (highlight: any) => {
    const highlightTable = document.getElementsByClassName('highlight-table');
    if (!highlightTable) throw new Error('Highlight table not found');

    const newRow = makeHighlightRow(highlight);
    highlightTable[0].appendChild(newRow);
}
