import { getPermalink } from "./services/highlight-service";

export const renderHighlightList = (highlights: any[]) => {
    const highlightList = document.createElement('div');
    highlightList.className = 'highlight-list';
    
    const highlightTable = document.createElement('table');
    highlightTable.className = 'highlight-table';

    highlights.forEach(highlight => {
        highlightTable.appendChild(makeHighlightRow(highlight));
    });

    highlightList.appendChild(highlightTable);

    document.body.appendChild(highlightList);
}

export const makeHighlightRow: any = (highlight: any) => {
    const highlightRow = document.createElement('tr');
    highlightRow.className = 'highlight-row';

    let htmlContent = `<td class="time">${formatDt(highlight.fromDateTime)}${formatActiveIcon(highlight.active)}</td>`;
    htmlContent += `<td class="icon">${formatIcons(highlight)}</td>`;
    htmlContent += `<td>`;
    htmlContent += `  <a href="${getPermalink(highlight)}" target="_blank">${highlight.title}</a>`;
    htmlContent += `  <span class="rank">${highlight.rank}</span>`;
    htmlContent += `</td>`;

    // Add the HTML content to the row as one string
    // This is more efficient than appending each element separately and 
    // avoids the need to create a new element for each piece
    highlightRow.innerHTML = htmlContent;

    return highlightRow;
}

const formatDt = (dt: string) => {
    const date = new Date(dt);
    return date.toLocaleTimeString().substring(0, 5);
}

const formatActiveIcon = (active: boolean) => {
    return active ? '<span class="active">●</span>' : '';
}

const formatIcons = (highlight: any) => {
    if (highlight.emoji) return highlight.emoji;

    const icons = highlight.iconList as string[];
    if (!highlight.iconList) return '';
    const iconBaseUrl = process.env.ICON_BASE_URL;
    if (!iconBaseUrl) {
        console.error('No icon base URL');
        return '';
    }
    return icons.map(iconFileName => {
        return `<img src="${iconBaseUrl}/${iconFileName}" />`;
    }).join('');
}

