export const renderLiveBox = () => {
    const liveBox = document.createElement('div')!;
    liveBox.id = 'live-box';
    liveBox.innerHTML = getRadarSvg() + '<div>Live</div>';
    
    const app = document.getElementById('app')!;
    app.appendChild(liveBox);
}

const getRadarSvg = () => {
    const backgroundColor = "#b6191f";
    const borderColor = "#ffffff";
    const radarColor = "#ffffff";
    const radarBackground = 'url(#backgroundGradient)';

    return `
    <svg
        id="radar-svg"
        width="320"
        height="320"
        viewBox="0 0 320 320.00001"
        version="1.1">
        <defs id="defs12002">
        <linearGradient
            id="linearGradient12950">
            <stop offset="0%" stop-color="${radarColor}" stop-opacity="0.4"/>
            <stop offset="100%" stop-color="${radarColor}" stop-opacity="0.0165"/>
        </linearGradient>
        <radialGradient id="backgroundGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="90%" style="stop-color:${backgroundColor};stop-opacity:1" />
            <stop offset="100%" style="stop-color:${backgroundColor};stop-opacity:0.5" />
        </radialGradient>
        <filter
            style="color-interpolation-filters:sRGB;"
            id="filter12648">
            <feFlood
                flood-opacity="0.27451"
                flood-color="rgb(0,0,0)"
                result="flood"
                id="feFlood12650" />
            <feComposite
                in="flood"
                in2="SourceGraphic"
                operator="in"
                result="composite1"
                id="feComposite12652" />
            <feGaussianBlur
                in="composite1"
                stdDeviation="5"
                result="blur"
                id="feGaussianBlur12654" />
            <feOffset
                dx="0"
                dy="0"
                result="offset"
                id="feOffset12656" />
            <feComposite
                in="SourceGraphic"
                in2="offset"
                operator="over"
                result="composite2"
                id="feComposite12658" />
        </filter>
        <filter
            style="color-interpolation-filters:sRGB;"
            id="filter12932">
            <feFlood
                flood-opacity="0.27451"
                flood-color="rgb(0,0,0)"
                result="flood"
                id="feFlood12934" />
            <feComposite
                in="flood"
                in2="SourceGraphic"
                operator="in"
                result="composite1"
                id="feComposite12936" />
            <feGaussianBlur
                in="composite1"
                stdDeviation="5"
                result="blur"
                id="feGaussianBlur12938" />
            <feOffset
                dx="0"
                dy="0"
                result="offset"
                id="feOffset12940" />
            <feComposite
                in="SourceGraphic"
                in2="offset"
                operator="over"
                result="composite2"
                id="feComposite12942" />
        </filter>
        <linearGradient
            xlink:href="#linearGradient12950"
            id="linearGradient12956"
            x1="21.766975"
            y1="904.59143"
            x2="159.81953"
            y2="821.828"
            gradientUnits="userSpaceOnUse"
            spreadMethod="pad" />
        <filter
            style="color-interpolation-filters:sRGB;"
            id="filter12962">
            <feFlood
                flood-opacity="0.27451"
                flood-color="rgb(0,0,0)"
                result="flood"
                id="feFlood12964" />
            <feComposite
                in="flood"
                in2="SourceGraphic"
                operator="in"
                result="composite1"
                id="feComposite12966" />
            <feGaussianBlur
                in="composite1"
                stdDeviation="5"
                result="blur"
                id="feGaussianBlur12968" />
            <feOffset
                dx="0"
                dy="0"
                result="offset"
                id="feOffset12970" />
            <feComposite
                in="SourceGraphic"
                in2="offset"
                operator="over"
                result="composite2"
                id="feComposite12972" />
        </filter>
        <filter
            style="color-interpolation-filters:sRGB;"
            id="filter13062">
            <feFlood
                flood-opacity="0.333333"
                flood-color="rgb(0,0,0)"
                result="flood"
                id="feFlood13064" />
            <feComposite
                in="flood"
                in2="SourceGraphic"
                operator="in"
                result="composite1"
                id="feComposite13066" />
            <feGaussianBlur
                in="composite1"
                stdDeviation="3"
                result="blur"
                id="feGaussianBlur13068" />
            <feOffset
                dx="0"
                dy="0"
                result="offset"
                id="feOffset13070" />
            <feComposite
                in="SourceGraphic"
                in2="offset"
                operator="over"
                result="fbSourceGraphic"
                id="feComposite13072" />
            <feColorMatrix
                result="fbSourceGraphicAlpha"
                in="fbSourceGraphic"
                values="0 0 0 -1 0 0 0 0 -1 0 0 0 0 -1 0 0 0 0 1 0"
                id="feColorMatrix13153" />
            <feFlood
                id="feFlood13155"
                flood-opacity="0.333333"
                flood-color="rgb(0,0,0)"
                result="flood"
                in="fbSourceGraphic" />
            <feComposite
                id="feComposite13157"
                in2="fbSourceGraphic"
                in="flood"
                operator="in"
                result="composite1" />
            <feGaussianBlur
                id="feGaussianBlur13159"
                in="composite1"
                stdDeviation="2.4"
                result="blur" />
            <feOffset
                id="feOffset13161"
                dx="0"
                dy="0"
                result="offset" />
            <feComposite
                id="feComposite13163"
                in2="offset"
                in="fbSourceGraphic"
                operator="over"
                result="composite2" />
        </filter>
        <filter
            style="color-interpolation-filters:sRGB;"
            id="filter13165">
            <feFlood
                flood-opacity="0.333333"
                flood-color="rgb(0,0,0)"
                result="flood"
                id="feFlood13167" />
            <feComposite
                in="flood"
                in2="SourceGraphic"
                operator="in"
                result="composite1"
                id="feComposite13169" />
            <feGaussianBlur
                in="composite1"
                stdDeviation="2.4"
                result="blur"
                id="feGaussianBlur13171" />
            <feOffset
                dx="0"
                dy="0"
                result="offset"
                id="feOffset13173" />
            <feComposite
                in="SourceGraphic"
                in2="offset"
                operator="over"
                result="composite2"
                id="feComposite13175" />
        </filter>
        <filter
            style="color-interpolation-filters:sRGB;"
            id="filter13181">
            <feFlood
                flood-opacity="0.333333"
                flood-color="rgb(0,0,0)"
                result="flood"
                id="feFlood13183" />
            <feComposite
                in="flood"
                in2="SourceGraphic"
                operator="in"
                result="composite1"
                id="feComposite13185" />
            <feGaussianBlur
                in="composite1"
                stdDeviation="2.4"
                result="blur"
                id="feGaussianBlur13187" />
            <feOffset
                dx="0"
                dy="0"
                result="offset"
                id="feOffset13189" />
            <feComposite
                in="SourceGraphic"
                in2="offset"
                operator="over"
                result="composite2"
                id="feComposite13191" />
        </filter>
        </defs>
        <sodipodi:namedview
            id="base"
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1.0"
            units="px">
        <sodipodi:guide
            position="159.28571,203.57144"
            orientation="1,0"
            id="guide12664" />
        <sodipodi:guide
            position="183.57143,160.00001"
            orientation="0,1"
            id="guide12666" />
        <sodipodi:guide
            position="159.28571,13.131984"
            orientation="0,1"
            id="guide12668" />
        </sodipodi:namedview>
        <g
            id="layer1"
            transform="translate(0,-732.36216)">
        <ellipse
            style="opacity:1;fill:${radarBackground};fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:5;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            id="path12548"
            cx="159.46616"
            cy="892.896"
            rx="149.46616"
            ry="149.46617" />
        <ellipse
            id="ellipse12948"
            style="opacity:1;fill:url(#linearGradient12956);fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:5;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            cx="159.46616"
            cy="892.89636"
            rx="149.46616"
            ry="149.46617" />
        <g
            id="g13177"
            style="filter:url(#filter13181)"
            transform="matrix(1.000041,0,0,0.99893319,-0.00653322,1.1258829)">
            <rect
                y="892.18005"
                x="157.35107"
                height="148.91985"
                width="3.9168477"
                id="rect12660"
                style="opacity:1;fill:${borderColor};fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:5;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
            <circle
                r="12.184875"
                cy="892.37726"
                cx="159.38489"
                id="path12944"
                style="opacity:1;fill:${borderColor};fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:5.63025093;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
        </g>
        <circle
            style="opacity:1;fill:#5599ff;fill-opacity:0.00649348;fill-rule:nonzero;stroke:${borderColor};stroke-width:3;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0.78341013"
            id="path12974"
            cx="160.14287"
            cy="892.36212"
            r="75" />
        </g>
    </svg>`;
}