import './style.css'

import { makeMap } from './map'
import { LngLatBounds, NavigationControl } from 'mapbox-gl';
import { fetchHighlightsByBounds } from './services/highlight-service';
import { isSignalrEnabled, initSignalr } from './signalr'
import { isJourneyEnabled, startJourney } from './journey';
import { renderLiveBox } from './radar';
import { renderHighlightList } from './linklist';
import { fetchTenantSettings } from './tenant';
import { renderFullscreenButton } from './fullscreen';
import { loadHighlightsAsMarkers } from './markers';
import { isSpeakerEnabled, speakAboutFeatureActivated } from './speaker';

const secretFromQs = window.location.pathname.split('/')[1];
const minRankThreshold = parseInt(process.env.MIN_RANK_THRESHOLD || '0');

if (!secretFromQs) {
    alert('No secret in URL path');
} else {
    fetchTenantSettings(secretFromQs!).then((tenant) => {
        console.debug('Tenant settings', tenant);

        const boundingBoxFromPolygon = getPolygonBoundingBox(tenant.geo_bounds);

        // Update the document title with the tenant title
        document.title = tenant.title + ' - ' + document.title;

        const geoBounds = LngLatBounds.convert(boundingBoxFromPolygon);

        const mapStyleId = tenant.mapbox_style_id ??
            'mapbox/satellite-streets-v12?optimize=true';

        makeMap('app', geoBounds, mapStyleId, tenant.mapbox_json).then((map) => {
            map.addControl(new NavigationControl());

            renderLiveBox();
        
            if (isSignalrEnabled) {
                initSignalr(map);
            }

            fetchHighlightsByBounds(geoBounds).then(async (highlights: any[]) => {
                console.debug('Highlights fetched', highlights)

                const filteredHighlights = highlights
                    .filter(x => x.rank > minRankThreshold);

                if (filteredHighlights.length === 0) {
                    console.warn('No highlights found in this area');
                    alert('No highlights received from Localix API in this area');
                    return;
                }

                loadHighlightsAsMarkers(map, filteredHighlights);

                if (isJourneyEnabled) {
                    startJourney(map, highlights);
                    renderFullscreenButton();
                }

                if (isSpeakerEnabled) {
                    speakAboutFeatureActivated();
                }

                renderHighlightList(filteredHighlights);
            });
        });
    })
}

const getPolygonBoundingBox = (geoJson: any) => {
    const bbox = require('geojson-bbox');
    return bbox(geoJson);
}