import { LngLatBounds } from 'mapbox-gl';

const accountId = process.env.LX_ACCOUNT_ID
const apiBaseUrl = process.env.LX_API_URL

export const fetchHighlightsByBounds = async (
        bounds: LngLatBounds, oldBounds?: LngLatBounds) => {
    const timeframe = 'today'

    const payload = {
        AccountId: accountId,
        DebugMode: false,

        Timeframe: timeframe,
        IncludePoints: false,

        BoundsX1: bounds.getNorthEast().lat,
        BoundsY1: bounds.getNorthEast().lng,
        BoundsX2: bounds.getSouthWest().lat,
        BoundsY2: bounds.getSouthWest().lng,

        OldBoundsX1: oldBounds ? oldBounds.getNorthEast().lat : bounds.getNorthEast().lat,
        OldBoundsY1: oldBounds ? oldBounds.getNorthEast().lng : bounds.getNorthEast().lat,
        OldBoundsX2: oldBounds ? oldBounds.getSouthWest().lat : bounds.getSouthWest().lat,
        OldBoundsY2: oldBounds ? oldBounds.getSouthWest().lng : bounds.getSouthWest().lng
    }

    const resp = await fetch(`${apiBaseUrl!}/in-bounds`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })

    if (!resp.ok) {
        throw new Error(`HTTP error, status: ${resp.status}`)
    }

    const jsonResp = await resp.json();
    const highlights = jsonResp['activeHighlights'] as any[]
    if (!highlights || highlights.length === 0) {
        // throw new Error('No highlights found')
    }

    const earlierHighlightsObj = jsonResp['earlierHighlights']
    if (earlierHighlightsObj && earlierHighlightsObj.length > 0) {
        const earlierHighlights = earlierHighlightsObj[0].value as any[]
        if (earlierHighlights) {
            highlights.push(...earlierHighlights)
        }
    }

    return highlights
};

export const fetchHighlightsByGeaArea = async (geoAreaId: string) => {
    const maxCount = 100;
    const maxDays = 1;
    const sortType = 'dt';

    const apiBaseUrl = process.env.LX_API_URL

    const apiEndpoint = `${apiBaseUrl}/highlights?` +
        `geoItemId=${geoAreaId}&` +
        `accountId=${accountId}&` +
        `sort=${sortType}&` +
        `maxdays=${maxDays}&` +
        `maxcount=${maxCount}&` +
        `arranger=false&` +
        `source=monitor`;

    console.log('Fetching highlights from', apiEndpoint);
    const resp = await fetch(apiEndpoint);
    if (!resp.ok) {
        throw new Error(`HTTP error, status: ${resp.status}`);
    }

    return await resp.json();
}

export const getPermalink = (highlight: any) => {
    const publicFeedBaseUrl = process.env.PUBLIC_FEED_BASE_URL;
    return `${publicFeedBaseUrl}/h/${highlight.highlightId}`;
}