import { isJourneyEnabled } from "./journey";

export const isSpeakerEnabled = process.env.SPEAKER_ENABLED?.toString() === 'true';

export const speakAboutFeatureActivated = () => {
    console.log('Speaker feature is activated');
    const text = 
        'Välkommen till Localix Monitor. ' +
        'Jag kommer att berätta för dig om det händer något nytt i området. ' +
        'Och du, chilla. Jag är inbyggd i webbläsaren och är ingen modern AI, ' +
        'jag kommer bytas ut snart... kul för dig... men inte för mig!';
    speak(text);
}

export const speakAboutUpcomingHighlight = (highlight: any, previousHighlight: any | null) => {
    if (isSpeakerEnabled === false) {
        console.warn('Speaker feature is disabled');
        return;
    }

    // Play notification sound
    /*
    const audio = new Audio('/assets/notification-sound.mp3');
    audio.play();
    */

    // Speak text
    const sourceName = highlight.tags[0];
    let text = ''
    
    if (isJourneyEnabled) {
        text = `Nu åker vi vidare till ${highlight.municipalityTitle} där `;
        text += `${sourceName} rapporterar: ${highlight.title}`;
    }
    else {
        text = `Nytt från ${sourceName}: ${highlight.title}`;
    }

    // TODO: logic around previous highlight, to make it more natural

    speak(text);
}

const speak = (text: string) => {
    const speech = new SpeechSynthesisUtterance(text);

    if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
    }

    speech.volume = 50;
    speech.rate = 1;
    speech.pitch = 1.3;
    speech.lang = 'sv-SE';

    speechSynthesis.speak(speech);

    console.log('Speaking:', text);
}