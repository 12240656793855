export const fetchTenantSettings = async (secret: string) => {
    const apiBaseUrl = process.env.DIRECTUS_API_URL
    const accessToken = process.env.DIRECTUS_API_TOKEN

    const qs = `access_token=${accessToken}` +
        `&filter[secret][_eq]=${secret}` +
        `&fields=geo_bounds,title,mapbox_style_id,mapbox_json`;
    const resp = await fetch(`${apiBaseUrl!}/items/tenants?${qs}`)

    if (!resp.ok) {
        throw new Error(`HTTP error, status: ${resp.status}`)
    }

    const jsonResp = await resp.json()
    const tenantObj = jsonResp.data[0]

    return tenantObj
}